<template>
  <div class="my-package pt-3 pt-md-0">
    <!-- <section
      class="px-3 pt-md-3 pb-0 px-md-5 pt-md-5 pb-md-0 mt-0 mt-md-0 mt-lg-0"
    > -->
      <NavbarOne
      class="card-top d-md-none d-lg-none"
          id="card-top"
        :showNavbar="showNavbar"
        :showCart="showCart"
        :showCartDesktop="showCartDesktop"
        :showSearch="showSearch"
        :showLogo="showLogo"
      >
      </NavbarOne>
    <!-- </section> -->
    <v-card class="ma-3 mt-16 ma-md-5">
      <custom-section>
        <template>
          <v-row>
            <v-col class="pa-3 pa-md-2 d-flex child-flex" cols="12" md="4" lg="4">
              <router-link :to="`/patient/promo/detail`">
                <v-img :aspect-ratio="16 / 9" src="@/assets/promo.png">
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height ma-0"
                      align="center"
                      justify="center"
                    >
                      <v-progress-circular
                        indeterminate
                        color="grey lighten-5"
                      ></v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
              </router-link>
            </v-col>
            <v-col class="pa-3 pa-md-2 d-flex child-flex" cols="12" md="4" lg="4">
              <v-img :aspect-ratio="16 / 9" src="@/assets/promo-3.png">
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height ma-0"
                    align="center"
                    justify="center"
                  >
                    <v-progress-circular
                      indeterminate
                      color="grey lighten-5"
                    ></v-progress-circular>
                  </v-row>
                </template>
              </v-img>
            </v-col>
            <v-col class="pa-3 pa-md-2 d-flex child-flex" cols="12" md="4" lg="4">
              <v-img :aspect-ratio="16 / 9" src="@/assets/promo-2.png">
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height ma-0"
                    align="center"
                    justify="center"
                  >
                    <v-progress-circular
                      indeterminate
                      color="grey lighten-5"
                    ></v-progress-circular>
                  </v-row>
                </template>
              </v-img>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="pa-3 pa-md-2 d-flex child-flex" cols="12" md="4" lg="4">
              <v-img :aspect-ratio="16 / 9" src="@/assets/promo-2.png">
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height ma-0"
                    align="center"
                    justify="center"
                  >
                    <v-progress-circular
                      indeterminate
                      color="grey lighten-5"
                    ></v-progress-circular>
                  </v-row>
                </template>
              </v-img>
            </v-col>
            <v-col class="pa-3 pa-md-2 d-flex child-flex" cols="12" md="4" lg="4">
              <v-img :aspect-ratio="16 / 9" src="@/assets/promo.png">
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height ma-0"
                    align="center"
                    justify="center"
                  >
                    <v-progress-circular
                      indeterminate
                      color="grey lighten-5"
                    ></v-progress-circular>
                  </v-row>
                </template>
              </v-img>
            </v-col>
            <v-col class="pa-3 pa-md-2 d-flex child-flex" cols="12" md="4" lg="4">
              <v-img :aspect-ratio="16 / 9" src="@/assets/promo-3.png">
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height ma-0"
                    align="center"
                    justify="center"
                  >
                    <v-progress-circular
                      indeterminate
                      color="grey lighten-5"
                    ></v-progress-circular>
                  </v-row>
                </template>
              </v-img>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="pa-3 pa-md-2 d-flex child-flex" cols="12" md="4" lg="4">
              <v-img :aspect-ratio="16 / 9" src="@/assets/promo-3.png">
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height ma-0"
                    align="center"
                    justify="center"
                  >
                    <v-progress-circular
                      indeterminate
                      color="grey lighten-5"
                    ></v-progress-circular>
                  </v-row>
                </template>
              </v-img>
            </v-col>
            <v-col class="pa-3 pa-md-2 d-flex child-flex" cols="12" md="4" lg="4">
              <v-img :aspect-ratio="16 / 9" src="@/assets/promo-2.png">
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height ma-0"
                    align="center"
                    justify="center"
                  >
                    <v-progress-circular
                      indeterminate
                      color="grey lighten-5"
                    ></v-progress-circular>
                  </v-row>
                </template>
              </v-img>
            </v-col>
            <v-col class="pa-3 pa-md-2 d-flex child-flex" cols="12" md="4" lg="4">
              <v-img :aspect-ratio="16 / 9" src="@/assets/promo.png">
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height ma-0"
                    align="center"
                    justify="center"
                  >
                    <v-progress-circular
                      indeterminate
                      color="grey lighten-5"
                    ></v-progress-circular>
                  </v-row>
                </template>
              </v-img>
            </v-col>
          </v-row>
        </template>
        <DialogMenu
          :tampilkan="dialog"
          @toggleDialog="(dialog) => toggleDialogMenu(dialog)"
        />
      </custom-section>
    </v-card>
  </div>
</template>

<script>
import CustomSection from "@/components/v2/section/index.vue";
// import TextField from "@/components/v2/input/text_field.vue";
import Auth from "@/service/auth_service";
import API from "@/service/api_service.js";
//import ButtonPackage from "@/components/v2/button/button_package.vue";
// import UserCard from "@/components/v2/sidebar/UserCard.vue";
// import Cart from "@/components/headers/Cart.vue";
import DialogMenu from "@/components/v2/dialog/dialogMenu.vue";
// import notification from "@/components/v2/notification/index.vue";
import NavbarOne from "@/components/v2/topbar/navbar_one.vue";

export default {
  components: {
    CustomSection,
    // TextField,
    // UserCard,
    // Cart,
    DialogMenu,
    // notification,
    NavbarOne,
    //  ButtonPackage,
  },
  data: () => ({
    showNavbar: true,
    showCart: true,
    showCartDesktop: true,
    showSearch: true,
    showLogo: false,
    dialog: false,
    testBtn: "",
    packages_check: [],
    getCategory: [],
    items: [
      {
        title: "VIRTU DIGILAB MCU 1A",
        realPrice: 1350000,
        discountPrice: 938000,
        subTitle: "8 Test Covered",
      },
      {
        title: "VIRTU DIGILAB MCU 2A",
        realPrice: 1350000,
        discountPrice: 1203000,
        subTitle: "18 Test Covered",
      },
      {
        title: "VIRTU DIGILAB MCU 3A",
        realPrice: 2950000,
        discountPrice: 1584000,
        subTitle: "24 Test Covered",
      },
    ],
    testItem: [
      {
        dataCategory: [
          {
            id: 1,
            name: "VIRTU DIGILAB MCU 1A",
            prices: [
              {
                amount: "999000",
              },
              {
                amount: "111111",
              },
              {
                amount: "22222",
              },
            ],
            total_products: 11,
          },
          {
            id: 2,
            name: "VIRTU DIGILAB MCU 2A",
            prices: [
              {
                amount: "1999000",
              },
              {
                amount: "22222",
              },
              {
                amount: "21212",
              },
            ],
            total_products: 14,
          },
          {
            id: 3,
            name: "VIRTU DIGILAB MCU 3A",
            prices: [
              {
                amount: "2999000",
              },
              {
                amount: "33333",
              },
              {
                amount: "313131",
              },
            ],
            total_products: 18,
          },
        ],
        id: 1,
        name: "General",
        param: 1,
      },
      {
        dataCategory: [
          {
            id: 1,
            name: "VIRTU DIGILAB MCU 1A",
            prices: [
              {
                amount: "999000",
              },
              {
                amount: "111111",
              },
              {
                amount: "22222",
              },
            ],
            total_products: 11,
          },
          {
            id: 2,
            name: "VIRTU DIGILAB MCU 2A",
            prices: [
              {
                amount: "1999000",
              },
              {
                amount: "22222",
              },
              {
                amount: "21212",
              },
            ],
            total_products: 14,
          },
          {
            id: 3,
            name: "VIRTU DIGILAB MCU 3A",
            prices: [
              {
                amount: "2999000",
              },
              {
                amount: "33333",
              },
              {
                amount: "313131",
              },
            ],
            total_products: 18,
          },
        ],
        id: 2,
        name: "Company",
        param: 2,
      },
      {
        dataCategory: [
          {
            id: 1,
            name: "VIRTU DIGILAB MCU 1A",
            prices: [
              {
                amount: "999000",
              },
              {
                amount: "111111",
              },
              {
                amount: "22222",
              },
            ],
            total_products: 11,
          },
          {
            id: 2,
            name: "VIRTU DIGILAB MCU 2A",
            prices: [
              {
                amount: "1999000",
              },
              {
                amount: "22222",
              },
              {
                amount: "21212",
              },
            ],
            total_products: 14,
          },
          {
            id: 3,
            name: "VIRTU DIGILAB MCU 3A",
            prices: [
              {
                amount: "2999000",
              },
              {
                amount: "33333",
              },
              {
                amount: "313131",
              },
            ],
            total_products: 18,
          },
        ],
        id: 3,
        name: "Family",
        param: 3,
      },
    ],
    getTestItem: [],
    checkFilter: false,
  }),
  async mounted() {
    this.$store.commit("setLoading", true);
    const param = this.$route.query.type;
    if (!param) {
      await API.get(`${process.env.VUE_APP_API_TRUST}package/get`, {
        Authorization: `Bearer ${Auth.getToken()}`,
      }).then((res) => {
        if (res.statusCode === 200) {
          this.packages_check = res.results;

          let map = new Map();
          for (let item of res.results) {
            if (!map.has(item.category)) {
              map.set(item.category, true);
              this.getCategory.push({
                name: item.category,
                id: item.category_id,
                param: item.sorter,
              });
            }
          }
          this.getCategory.map((element) => {
            element.dataCategory = res.results.filter((dataFilter) => {
              return dataFilter.category_id == element.id;
            });

            return element;
          });
          //console.log(this.getCategory);
        }
      });
    } else {
      await API.get(`${process.env.VUE_APP_API_TRUST}package/get`, {
        Authorization: `Bearer ${Auth.getToken()}`,
      }).then((res) => {
        if (res.statusCode === 200) {
          this.packages_check = res.results;

          let map = new Map();
          for (let item of res.results) {
            if (!map.has(item.category)) {
              map.set(item.category, true);
              this.getCategory.push({
                name: item.category,
                id: item.category_id,
                param: item.sorter,
              });
            }
          }
          this.getCategory.map((element) => {
            element.dataCategory = res.results.filter((dataFilter) => {
              return dataFilter.category_id == element.id;
            });

            return element;
          });

          this.getCategory = this.getCategory.filter((dataFilter) => {
            return dataFilter.param == param;
          });
        }
      });
    }
    this.getTestItem = this.testItem;
    this.$store.commit("setLoading", false);
  },
  methods: {
    formater(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    filterPackages(id) {
      if (id == "0") {
        document.getElementById("popular").classList.add("active");
        document.getElementById("general").classList.remove("active");
        document.getElementById("company").classList.remove("active");
        document.getElementById("family").classList.remove("active");
        document.getElementById("category").classList.remove("active");
      }
      if (id == "1") {
        document.getElementById("popular").classList.remove("active");
        document.getElementById("general").classList.add("active");
        document.getElementById("company").classList.remove("active");
        document.getElementById("family").classList.remove("active");
        document.getElementById("category").classList.remove("active");
      }
      if (id == "2") {
        document.getElementById("popular").classList.remove("active");
        document.getElementById("general").classList.remove("active");
        document.getElementById("company").classList.add("active");
        document.getElementById("family").classList.remove("active");
        document.getElementById("category").classList.remove("active");
      }
      if (id == "3") {
        document.getElementById("popular").classList.remove("active");
        document.getElementById("general").classList.remove("active");
        document.getElementById("company").classList.remove("active");
        document.getElementById("family").classList.add("active");
        document.getElementById("category").classList.remove("active");
      }
      if (id == "all") {
        document.getElementById("popular").classList.remove("active");
        document.getElementById("general").classList.remove("active");
        document.getElementById("company").classList.remove("active");
        document.getElementById("family").classList.remove("active");
        document.getElementById("category").classList.add("active");
      }

      if (id == "all") {
        this.testItem = this.getTestItem;
      } else {
        this.testItem = this.getTestItem;
        this.testItem = this.testItem.filter((dataFilter) => {
          return dataFilter.id == id;
        });
      }
    },
    async openDialog() {
      this.dialog = true;
    },
    toggleDialogMenu(toggle) {
      this.dialog = toggle;
    },
  },
  computed: {
    realPriceFormater() {
      return this.realPrice
        ? this.realPrice.toLocaleString("en-US", {
            style: "currency",
            currency: "IDR",
            maximumSignificantDigits: 2,
          })
        : "";
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/custom.scss";
.my-package {
  display: flex;
  flex-direction: column;
  padding: 1px 0 40px 0;
  a {
    text-decoration: none;
  }
  .h-full {
    height: 100%;
    border: 1px solid #777777;
  }
  &__section {
    padding: 0 20px;
  }
}
.title-page {
  color: $blue_1;
  margin-top: 10px;
  font-size: 14px;
  text-transform: capitalize !important;
}

.text-field {
  max-width: 70%;
  margin-top: -3px;
}


.card-col {
  padding: 10px 20px;
}

.cart {
  margin-top: -20px;
}
.cart-right {
  text-align: right;
  justify-content: flex-end;
  background: none;
  width: 95%;
  margin-top: -19px;
  float: right;
}

.v-btn:not(.v-btn--round).v-size--default {
  min-width: 105px;
}
.theme--light.v-btn {
  color: #00a4b3;
  border-radius: 5px;
  font-weight: bold;
}

.theme--light.v-btn-toggle:not(.v-btn-toggle--group) {
  background: transparent;
}

.theme--light.v-btn-toggle:not(.v-btn-toggle--group) .v-btn.v-btn {
  border-color: #00a4b3 !important;
}

.v-btn:not(.v-btn--round).v-size--default {
  height: 30px;
  width: 162px;
  padding: 0 16px;
  text-transform: capitalize;
}

.v-btn {
  border-left-style: 1;
}

.active {
  background-color: #00a4b3;
  color: white !important;
}

.btn-filter {
  font-size: 12px;
}

@media screen and (max-width: 600px) {
  .card-top {
    top: 0;
    z-index: 999;
    width: 100% !important;
  }

  .user-card {
    max-width: 64% !important;
    margin-left: 139px;
    margin-right: 16px;
    margin-top: -40px;
  }

  .cart-right {
    text-align: right;
    justify-content: flex-end;
    background: none;
    width: 95%;
    margin-top: -19px;
  }

  .cart {
    margin-left: 9px;
    margin-right: 63px;
    z-index: 1;
  }
  .text-field {
    margin-left: 0px !important;
    max-width: 85%;
  }
  .bell {
    margin-left: 8px;
  }
  .notification {
    height: 55px;
    border-radius: 3px;
    margin-top: -15px;
    margin-left: -88px;
  }
}
</style>
